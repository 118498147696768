<script setup lang="ts">
import * as Sentry from '@sentry/vue';

import { useMainStore } from '~/store/index.ts';

defineProps<{
    minimumCount?: number;
    helper?: boolean;
}>();

const { accounts, user } = storeToRefs(useMainStore());

const { $accounts, $setToken: setToken, $removeToken: removeToken } = useNuxtApp();

onMounted(async () => {
    await $accounts();

    // just in case…
    setTimeout(validateAccounts, 1000);
    setInterval(validateAccounts, 60000);
});

const router = useRouter();

const switchAccount = async (token: string) => {
    await setToken(token);
    setTimeout(() => window.location.reload(), 300);
};
const addAccount = async () => {
    await setToken(null);
    await router.push({ name: 'user' });
    setTimeout(() => window.location.reload(), 300);
};
const validateAccounts = async () => {
    for (const [username, { token }] of Object.entries(accounts.value)) {
        try {
            const user = await $fetch('/api/user/current?no_cookie', {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
            if (!user || user.username !== username) {
                await removeToken(username);
            }
        } catch (error) {
            Sentry.captureException(error);
            await removeToken(username);
        }
    }
};
</script>

<template>
    <div v-if="accounts !== undefined && (minimumCount === undefined || Object.keys(accounts).length >= minimumCount)">
        <div class="card">
            <div class="card-body d-flex flex-column flex-lg-row">
                <button
                    v-for="account in accounts"
                    class="m-1 btn btn-outline-primary border-0"
                    :disabled="!!user && account.account.username === user.username"
                    @click="switchAccount(account.token)"
                >
                    <Avatar :user="account.account" dsize="3rem" />
                    <p class="mb-0">
                        @{{ account.account.username }}
                    </p>
                </button>
                <button
                    v-if="Object.keys(accounts).length < 5"
                    class="m-1 btn btn-outline-primary border-0 btn-sm"
                    @click="addAccount"
                >
                    <Icon v="plus-circle" :size="3" />
                    <p class="mb-0">
                        <T>user.accountSwitch.add</T>
                    </p>
                </button>
            </div>
        </div>
        <p v-if="helper" class="text-muted small">
            <T>user.accountSwitch.helper</T>
        </p>
    </div>
</template>
